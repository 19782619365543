<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="action" slot-scope="{ columnData }">
			<a-button type="link" @click="Detail(columnData.loanId)" :disabled="!$hasPermission('ROLE_RENEWAL_PENDING_VIEW')">{{ $t('page.checkSubmit') }}</a-button>
		</template>
	</search-list-table>
</template>
<script>
import { apiBranchList } from '@/api/branch'
import { apiIpfDisburseCheck } from '@/api/insuranceRenewal'
export default {
	data() {
		return {
			branchOfficeList: [],
			expireDayAsc: 1
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.applyInfo.cloums.caseId',
					type: 'input',
					validateField: ['caseId']
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityNum']
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 80,
					align: 'center'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'caseId',
					align: 'center',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'registrationNumbers',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '-')
				},
				{
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityNum',
					align: 'center'
				},
				{
					title: 'IPF Renewal Approved',
					dataIndex: 'recommendAmount',
					align: 'center',
					customRender: (v, o) => {
						return <a-statistic precision={0} value={o.recommendAmount === null ? ' - ' : o.recommendAmount} />
					}
				},
				{
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 150
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiIpfDisburseCheck(initParams)
		},
		// 查看详情
		Detail(id) {
			this.$router.push({
				path: 'renewalDetail',
				query: {
					id,
					from: '/home/insuranceRenewal/renewalPending'
				}
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>

import { _axios } from '@/plugins/axios'

export const apiIpfApply = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/ipf/apply/list/apply/info`,
		method: method,
		data: dataJson
	})
}
export const apiIpfCheck = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/ipf/apply/list/apply/check`,
		method: method,
		data: dataJson
	})
}
export const apiIpfDisburseCheck = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/ipf/apply/list/waitingDisburse/check`,
		method: method,
		data: dataJson
	})
}
export const apiIpfDisburse = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/ipf/apply/list/disburse`,
		method: method,
		data: dataJson
	})
}
export const apiIpfApplyDetail = (dataJson, notify, method = 'get') => {
	return _axios({
		url: `/car/loan/ipf/apply/${dataJson}`,
		method: method,
		notify: notify === false ? false : true
	})
}
export const apiIpfSave = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/ipf/apply/info/save`,
		method: method,
		data: dataJson
	})
}
export const apiIpfTryCalculate = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/ipf/apply/applyTryCalculate/insurance`,
		method: method,
		data: dataJson
	})
}
export const apiSupplement = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/ipf/apply/info/supplement`,
		method: method,
		data: dataJson
	})
}
export const apiApplyConfirmInsuranceInfo = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/ipf/apply/applyConfirmInsuranceInfo`,
		method: method,
		data: dataJson
	})
}
